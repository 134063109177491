<template>
  <div>
    <b-modal
      :visible="isEventHandlerSidebarActive"
      :title="$t('Calendars')"
      size="lg"
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <b-container
        fluid
      >
        <b-form-group
          :label="$t('Title')"
          label-for="event-title"
        >
          <b-form-input
            id="event-title"
            v-model="title"
            autofocus
            trim
            placeholder="Event Title"
          />

        </b-form-group>

        <b-form-group
          :label="$t('Start Date')"
          label-for="start-date"
        >
          <flat-pickr
            v-model="start"
            class="form-control"
            :config="configs"
          />

        </b-form-group>

        <b-form-group
          :label="$t('End Date')"
          label-for="end-date"
        >
          <flat-pickr
            v-model="end"
            class="form-control"
            :config="configs"
          />
        </b-form-group>

        <!-- All Day -->
        <b-form-group>
          <b-form-checkbox
            v-model="allDay"
            name="check-button"
            switch
            inline
          >
            {{ $t('All Day') }}
          </b-form-checkbox>
        </b-form-group>

        <b-form-group
          :label="$t('Event URL')"
          label-for="event-url"
        >
          <b-form-input
            id="event-url"
            v-model="url"
            type="url"
            placeholder="htttps://www.google.com"
            trim
          />
        </b-form-group>

        <!-- Textarea -->
        <b-form-group
          :label="$t('Description')"
          label-for="event-description"
        >
          <b-form-textarea
            id="event-description"
            v-model="extendedProps.description"
          />
        </b-form-group>
        <GmapMap
          ref="mapRef"
          :style="'height:500px'"
          :center="dataCenter"
          :zoom="zoom"
        >
          <GmapMarker
            :draggable="false"
            :position="dataCenter"
            :clickable="false"
          />
        </GmapMap>
      </b-container>

      <template #modal-footer>
        <div class="w-100">

          <b-button
            v-if="id !== null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            size="sm"
            class="float-left  mb-0"
            @click="removeEvent"
          >
            {{ $t('Delete') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            size="sm"
            class="float-right"
            @click="$emit('update:is-event-handler-sidebar-active', false)"
          >
            {{ $t('Cancel') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-2"
            @click="validationForm"
          >
            {{ id !== null ? $t('Update') : $t('Add') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, VBModal, BContainer, BRow, BCol, BFormSelect, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import store from '@/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line import/extensions
import { Thai } from 'flatpickr/dist/l10n/th.js'
// import formValidation from '@core/comp-functions/forms/form-validation'
// import { ref, toRefs } from '@vue/composition-api'
// import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BModal,
    BContainer,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    eventData: {
      type: Object,
      required: true,
    },
    removeEventInCalendar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dataCenter: { lat: 16.056734, lng: 103.652653 },
      zoom: 12,
      configs: {
        enableTime: true,
        dateFormat: 'd-m-Y H:i ',
        locale: Thai,
      },
      id: null,
      title: '',
      start: '',
      end: '',
      allDay: false,
      url: '',
      extendedProps: {
        location: '',
        description: '',
      },

      required,
    }
  },
  watch: {
    isEventHandlerSidebarActive(val) {
      if (val) {
        if (Object.entries(this.eventData).length === 0) {
          this.initValues()
        } else {
          const {
            id,
            title,
            start,
            end,
            allDay,
            url,
            extendedProps,
          } = this.eventData
          this.id = id
          this.title = title
          this.start = start
          this.end = end
          this.allDay = allDay
          this.url = url
          this.extendedProps = extendedProps
          if (extendedProps.location !== '') {
            const lat = extendedProps.location.coordinates[1]
            const lng = extendedProps.location.coordinates[0]
            this.dataCenter.lat = lat
            this.dataCenter.lng = lng
            // this.$refs.mapRef.$mapPromise.then(map => {
            //   map.panTo({ lat, lng })
            // })
          }
        }
      }
    },
  },
  methods: {
    removeEvent() {
      const eventId = this.id
      if (eventId !== null) {
        this.$bvModal
          .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'danger',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          })
          .then(value => {
            if (value) {
              store.dispatch(`${this.storeModuleName}/removeEvent`, { id: eventId }).then(() => {
                this.$emit('update:is-event-handler-sidebar-active', false)
                this.removeEventInCalendar(eventId)
              })
            }
          })
      }
    },
    initValues() {
      this.id = null
      this.title = ''
      this.start = ''
      this.end = ''
      this.allDay = false
      this.url = ''
      this.extendedProps = {
        location: '',
        description: '',
      }
    },
    validationForm() {
      const obj = {
        id: this.id,
        title: this.title,
        start: this.start,
        end: this.end,
        allDay: this.allDay,
        url: this.url,
        extendedProps: this.extendedProps,
      }
      this.$emit('update:show', true)
      if (this.id !== null) {
        store
          .dispatch(`${this.storeModuleName}/updateEvent`, obj)
          .then(result => {
            this.$emit('update:is-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)
            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      } else {
        delete obj.id
        store
          .dispatch(`${this.storeModuleName}/addEvent`, obj)
          .then(result => {
            this.$emit('update:is-sidebar-active', false)
            this.$emit('update:show', false)
            console.log('fetch Success : ', result)
            // this.showToast('success', 'bottom-right')
          })
          .catch(error => {
            console.log('fetchUsers Error : ', error)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Error'),
                icon: 'ErrorIcon',
                variant: 'danger',
                text: this.$t(error.response.data.message),
              },
            })
            this.$emit('update:show', false)
            // this.showToast('danger', 'bottom-right')
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

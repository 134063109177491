<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="p-2">
      <!-- <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        aria-controls="sidebar-add-new-event"
        variant="primary"
        block
        @click="addNew"
      >
        {{ $t('Add Event') }}
      </b-button> -->
      <div>
        <h5 class="app-label section-label mb-1">
          <span class="align-middle">{{ $t('Calendars') }}</span>
        </h5>
        <!-- <b-form-checkbox
          v-model="checkAll"
          class="mb-1"
        >
          {{ $t('View All') }}
        </b-form-checkbox> -->
        <b-form-group>
          <b-form-checkbox-group
            v-model="selectedCalendars"
            name="event-filter"
            stacked
          >
            <b-form-checkbox
              v-for="(item, index) in calendarOptions"
              :key="index"
              name="event-filter"
              :value="item._id"
              class="mb-1"
              :class="`custom-control-${item.colorName}`"
            >
              {{ showFromCurrentLanguage(item.name) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {
  BButton, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

// import useCalendarSidebar from './useCalendarSidebar'

export default {
  directives: {
    Ripple,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    addNew: {
      type: Function,
      require: true,
    },
    storeModuleName: {
      type: String,
      required: true,
    },
    refetchEvents: {
      type: Function,
      required: true,
    },
  },
  computed: {
    calendarOptions() {
      return store.state.queueMonitor.calendarOptions
    },
    selectedCalendars: {
      get() {
        return store.state[this.storeModuleName].selectedCalendars
      },
      set(value) {
        store.commit(`${this.storeModuleName}/SET_SELECT_CALENDAR`, value)
        this.refetchEvents()
      },
    },
  },
  methods: {
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>

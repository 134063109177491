import axios from '@axios'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      // {
      //   color: 'danger',
      //   label: 'Personal',
      // },
      // {
      //   color: 'primary',
      //   label: 'Business',
      // },
      // {
      //   color: 'warning',
      //   label: 'Family',
      // },
      // {
      //   color: 'success',
      //   label: 'Holiday',
      // },
      // {
      //   color: 'info',
      //   label: 'ETC',
      // },
    ],
    selectedCalendars: [],
  },
  getters: {},
  mutations: {
    SET_CALENDAR_TYPE(state, payload) {
      state.calendarOptions = payload
      state.selectedCalendars = payload.map(p => p._id)
    },
    SET_SELECT_CALENDAR(state, payload) {
      state.selectedCalendars = payload
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getCalendarsType(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/partner/hero/store-queue/queueMonitor/getCalendarsType')
          .then(response => {
            resolve(response.data.data)
            ctx.commit('SET_CALENDAR_TYPE', response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/web/partner/hero/store-queue/queueMonitor/get', {
            params: {
              calendars: calendars.join(','),
            },
          })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/web/partner/hero/store-queue/queueMonitor/add', { event })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/web/partner/hero/store-queue/queueMonitor/update', { event })
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/web/partner/hero/store-queue/queueMonitor/delete?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
